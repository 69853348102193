<template>
  <div class="profile">
    <div class="container">
      <iq-title class-name="desktopHeader"> Мой профиль </iq-title>

      <el-form
        ref="formProfile"
        :model="formProfile"
        :rules="rules"
        label-width="150px"
        class="profile__form"
        @submit.native.prevent
      >
        <el-row :gutter="50">
          <el-col :xs="24" :sm="12">
            <iq-input-form
              v-model="formProfile.first_name"
              full-width
              disabled
              label="Имя"
              prop="first_name"
            />

            <iq-input-form
              v-model="formProfile.last_name"
              full-width
              disabled
              label="Фамилия"
              prop="last_name"
            />

            <iq-input-form
              v-model="formProfile.email"
              full-width
              label="Email"
              prop="email"
            />

            <iq-input-form
              v-model="formProfile.phone"
              v-mask="'+7 ### ###-##-##'"
              full-width
              label="Телефон"
              prop="phone"
            />
          </el-col>
          <el-col :xs="24" :sm="12">
            <iq-input-form
              v-model="formProfile.role"
              full-width
              disabled
              label="Роль в системе"
              prop="role"
            />

            <iq-input-form
              v-model="formProfile.password"
              full-width
              label="Пароль"
              prop="password"
              show-password
            />

            <iq-input-form
              v-model="formProfile.checkPassword"
              full-width
              label="Повторите пароль"
              prop="checkPassword"
              show-password
            />
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :xs="24" :sm="12" class="df jc-fe">
            <iq-button @onClick="handleSubmitForm">
              Сохранить изменения
            </iq-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  GET_PROFILE_FROM_STATE,
  UPDATE_PROFILE,
} from '@/views/profile/store/actions'
import { mapActions, mapGetters } from 'vuex'
import { usersRoles } from '@/constants/roles'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqInputForm from '@/views/ui/components/input/IqInputForm'
import IqTitle from '@/views/ui/components/typography/IqTitle'

export default {
  name: 'ProfileSettings',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=1200, initial-scale=1',
      },
    ],
  },
  components: { IqButton, IqInputForm, IqTitle },
  data() {
    return {
      formProfile: {
        first_name: '',
        last_name: '',
        role: '',
        email: '',
        phone: '',
        password: '',
        checkPassword: '',
      },
      rules: {
        role: {
          required: true,
          message: 'Выберите роль',
          trigger: 'blur',
        },
        email: [
          {
            required: true,
            message: 'Заполните email',
            trigger: 'blur',
          },
          {
            type: 'email',
            required: true,
            message: 'Формат поля email@email.com',
          },
        ],
        phone: {
          required: true,
          message: 'Заполните телефон',
          trigger: 'blur',
        },
        password: {
          required: true,
          min: 4,
          max: 18,
          message: 'Пароль слишком короткий',
          trigger: 'blur',
        },
        checkPassword: {
          required: true,
          validator: this.validatePass,
          message: 'Пароли должны совпадать',
          trigger: 'blur',
        },
      },
      usersRoles,
    }
  },
  computed: {
    ...mapGetters({ profile: GET_PROFILE_FROM_STATE }),
  },
  watch: {
    profile: {
      immediate: true,
      deep: true,
      handler(val) {
        this.formProfile.first_name = val.first_name
        this.formProfile.last_name = val.last_name
        this.formProfile.role = val.role
        this.formProfile.email = val.email
        this.formProfile.phone = val.phone
      },
    },
  },
  methods: {
    ...mapActions({
      updateProfile: UPDATE_PROFILE,
    }),
    validatePass(rule, value, callback) {
      if (!this.formProfile.password || value !== this.formProfile.password) {
        callback(new Error())
      } else {
        callback()
      }
    },
    handleSubmitForm() {
      this.$refs.formProfile.validate(valid => {
        if (valid) {
          this.updateProfile({
            ...this.formProfile,
            isOrdinaryUser: true,
            clearPassword: this.clearPassword,
          })
        }
      })
    },
    clearPassword() {
      this.formProfile.password = ''
      this.formProfile.checkPassword = ''
    },
  },
}
</script>

<style lang="sass">
.profile
  padding-bottom: 50px
  &__form
    background: $color-white
    border: $border-default
    box-sizing: border-box
    box-shadow: $box-shadow-default
    border-radius: 4px
    padding: 35px 35px 100px
</style>
